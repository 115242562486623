"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadSlim = void 0;
const Circle_1 = require("./Shapes/Circle");
const Life_1 = require("./Updaters/Life");
const Connect_1 = require("./Interactions/External/Connect");
const Opacity_1 = require("./Updaters/Opacity");
const Image_1 = require("./Shapes/Image");
const Polygon_1 = require("./Shapes/Polygon");
const Bubble_1 = require("./Interactions/External/Bubble");
const Attract_1 = require("./Interactions/External/Attract");
const Grab_1 = require("./Interactions/External/Grab");
const Star_1 = require("./Shapes/Star");
const Attract_2 = require("./Interactions/Particles/Attract");
const Square_1 = require("./Shapes/Square");
const StrokeColor_1 = require("./Updaters/StrokeColor");
const Color_1 = require("./Updaters/Color");
const Collisions_1 = require("./Interactions/Particles/Collisions");
const Angle_1 = require("./Updaters/Angle");
const OutModes_1 = require("./Updaters/OutModes");
const Repulse_1 = require("./Interactions/External/Repulse");
const Line_1 = require("./Shapes/Line");
const Bounce_1 = require("./Interactions/External/Bounce");
const Text_1 = require("./Shapes/Text");
const Links_1 = require("./Interactions/Particles/Links");
const Size_1 = require("./Updaters/Size");
async function loadSlim(tsParticles) {
    await (0, Attract_1.loadExternalAttractInteraction)(tsParticles);
    await (0, Bounce_1.loadExternalBounceInteraction)(tsParticles);
    await (0, Bubble_1.loadExternalBubbleInteraction)(tsParticles);
    await (0, Connect_1.loadExternalConnectInteraction)(tsParticles);
    await (0, Grab_1.loadExternalGrabInteraction)(tsParticles);
    await (0, Repulse_1.loadExternalRepulseInteraction)(tsParticles);
    await (0, Attract_2.loadParticlesAttractInteraction)(tsParticles);
    await (0, Collisions_1.loadParticlesCollisionsInteraction)(tsParticles);
    await (0, Links_1.loadParticlesLinksInteraction)(tsParticles);
    await (0, Circle_1.loadCircleShape)(tsParticles);
    await (0, Image_1.loadImageShape)(tsParticles);
    await (0, Line_1.loadLineShape)(tsParticles);
    await (0, Polygon_1.loadPolygonShape)(tsParticles);
    await (0, Square_1.loadSquareShape)(tsParticles);
    await (0, Star_1.loadStarShape)(tsParticles);
    await (0, Text_1.loadTextShape)(tsParticles);
    await (0, Life_1.loadLifeUpdater)(tsParticles);
    await (0, Opacity_1.loadOpacityUpdater)(tsParticles);
    await (0, Size_1.loadSizeUpdater)(tsParticles);
    await (0, Angle_1.loadAngleUpdater)(tsParticles);
    await (0, Color_1.loadColorUpdater)(tsParticles);
    await (0, StrokeColor_1.loadStrokeColorUpdater)(tsParticles);
    await (0, OutModes_1.loadOutModesUpdater)(tsParticles);
}
exports.loadSlim = loadSlim;
